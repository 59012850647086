<template>
  <div class="user-recharge">
    <h5 class="">订单列表</h5>
    <div class="main mt-3">
      <table class="table">
        <thead>
          <tr>
            <th>订单号</th>
            <th>产品</th>
            <th>类型</th>
            <th>创建时间</th>
            <th>开通时间</th>
            <th>状态</th>
            <th>金额</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in list" :key="item.index">
            <td>{{ item.orderNumber }}</td>
            <td>{{ item.game }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.created_at }}</td>
            <td>{{ item.finished_at }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import User from "@/model/User"
export default {
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      User.getOrders().then(response => {
        if(response.data.code === 0) {
          this.list = response.data.data;
        }
      })
    }
  }
}
</script>